import React from 'react'

import { CustomToast, LoadingModal } from '@akrostechnologies/pei-business-ui'
import { useToast } from '../lib/toast';

const ToastContainer: React.FC = () => {
  const {
    message,
    isVisible,
    setToastVisibility,
    loadingImage,
    loadingMessage,
    loadingVisibility,
    setLoadingModalVisibility,
  } = useToast()

  const toast = isVisible ? (
    <CustomToast
      message={message}
      onPressed={() => {
        setToastVisibility(false)
      }}
    />
  ) : null

  const loading = (
    <LoadingModal
      isVisible={loadingVisibility}
      message={loadingMessage}
      image={loadingImage}
      onPressed={() => {
        setLoadingModalVisibility(false)
      }}
    />
  )

  return (
    <div>
      {toast}
      {loading}
    </div>
  )
}

export default ToastContainer
