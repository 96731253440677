import React from 'react'
import { Text } from '@akrostechnologies/pei-business-ui'

const ExpiredLink: React.FC = () => (
    <>
        <Text variant="small" style={{
          paddingBottom: 30, fontSize: 22, color: '#8B0000', fontWeight: 'bold', textAlign: 'center',
        }}>
            El proceso ya se encuentra completado.
        </Text>
    </>
)

export default ExpiredLink
