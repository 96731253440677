import React from 'react'
import { createContainer } from 'unstated-next'

const ToastContext = createContainer(() => {
  const [message, setMessage] = React.useState<string>('')
  const [isVisible, setIsVisible] = React.useState<boolean>(false)

  const [loadingMessage, setLoadingMessage] = React.useState<string>('')
  const [loadingVisibility, setLoadingVisibility] = React.useState<boolean>(
    false,
  )
  const [loadingImage, setLoadingImage] = React.useState<
    'check' | 'loading' | ''
  >('')

  return {
    message,
    setMessage,
    isVisible,
    setIsVisible,
    loadingMessage,
    setLoadingMessage,
    loadingVisibility,
    setLoadingVisibility,
    loadingImage,
    setLoadingImage,
  }
})

export default ToastContext
