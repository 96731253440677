import React from 'react'
import { UIProvider } from '@akrostechnologies/pei-business-ui'
import ToastProvider from './toast-provider';
import ToastContainer from '../components/toast-container';
import { ApplicantProvider } from './applicant-provider';

const AppProvider: React.FC = ({ children }) => (
    <UIProvider theme="multimoney">
        <ApplicantProvider>
            <ToastProvider>
                {children}
                <ToastContainer />
            </ToastProvider>
        </ApplicantProvider>
    </UIProvider>
)

export default AppProvider
