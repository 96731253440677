import React from 'react'
import { Schema as YupSchema, ValidationError } from 'yup'

type FormalConfig<Schema> = {
  initialValues: Schema
  validationSchema: YupSchema<Schema>
  canSubmit?: (values: Schema) => boolean
  onSubmit: (values: Schema) => Promise<void>
}

type FormalErrors<Schema> = {
  [K in keyof Schema]?: Schema[K] extends object
    ? FormalErrors<Schema[K]>
    : string
}

const useFormal = <Schema>(config: FormalConfig<Schema>) => {
  const mountedRef = React.useRef<boolean>()
  const [values, setValues] = React.useState<Schema>(config.initialValues)
  const [errors, setErrors] = React.useState<FormalErrors<Schema>>({})
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  React.useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])
  const submitDisabled = React.useMemo(
    () => !(config.canSubmit ? config.canSubmit(values) : true) || isSubmitting,
    [config, isSubmitting, values],
  )

  const change = React.useCallback(
    <Field extends keyof Schema>(field: Field, value: Schema[Field]) => {
      setValues((p) => ({ ...p, [field]: value }))
    },
    [],
  )

  const submit = React.useCallback(async (newValues: Schema | null = null) => {
    try {
      if (submitDisabled) return
      const generatedValues = newValues || values
      setErrors({})
      config.validationSchema.validateSync(generatedValues, { abortEarly: false })
      setIsSubmitting(true)
      await config.onSubmit(generatedValues)
      if (mountedRef.current) setIsSubmitting(false)
    } catch (err) {
      if (err instanceof ValidationError) {
        setErrors(
          err.inner.reduce((acc, e) => ({ ...acc, [e.path]: e.message }), {}),
        )
      }
    }
  }, [config, submitDisabled, values])

  const onBlur = React.useCallback(
    async (field: keyof Schema) => {
      try {
        await config.validationSchema.validateSync(values, {
          abortEarly: false,
        })
        setErrors({})
        return {
          error: undefined,
        }
      } catch (err) {
        if (err instanceof ValidationError) {
          let errr = Object.entries(errors)
          const error = err.inner.filter((item) => item.path === field)[0]
          if (error) {
            const errorItem = errr.filter((item) => item[0] === error.path)[0]
            if (errorItem) {
              const index = errr.findIndex((item) => item[0] === error.path)
              errr[index] = [error.path, error.errors[0]]
            } else errr.unshift([error.path, error.errors[0]])
          } else errr = Object.entries(errors).filter((item) => item[0] !== field)
          setErrors(errr.reduce((acc, e) => ({ ...acc, [e[0]]: e[1] }), {}))
          return {
            error: errr.filter((item) => item[0] === field)[0],
          }
        }
        return {
          error: err.message,
        }
      }
    },
    [config, errors, values],
  )

  const getFieldProps = React.useCallback(
    (field: keyof Schema) => ({
      disabled: isSubmitting,
      error: errors[field],
      value: values[field],
      onChangeText: (value: Schema[typeof field]) => change(field, value),
      onSubmitEditing: () => submit(),
    }),
    [change, errors, isSubmitting, submit, values],
  )

  const getErrors = React.useCallback(
    (field: keyof Schema) => ({
      onBlur: async () => onBlur(field),
    }),
    [onBlur],
  )

  const setFieldProps = React.useCallback(
    (field: keyof Schema, value: string | number) => {
      setValues((p) => ({ ...p, [field]: value }))
    },
    [setValues],
  )

  const getSubmitButtonProps = React.useCallback(
    () => ({
      disabled: submitDisabled,
      onPressOut: () => submit(),
    }),
    [submit, submitDisabled],
  )

  return {
    values,
    errors,
    change,
    submit,
    getFieldProps,
    getSubmitButtonProps,
    setFieldProps,
    getErrors,
  }
}

export { useFormal }
