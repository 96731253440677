import React from 'react';
import { useParams } from 'react-router';
import * as onfido from 'onfido-sdk-ui';
import { styled, Text } from '@akrostechnologies/pei-business-ui';
import { useOnfido } from '../lib/onfido';
import ValidateIdentityResult from './validate-identity-result';
import ExpiredLink from './expired-link';

const StyledContainer = styled.div`
  .onfido-sdk-ui-NavigationBar-back {
    display: none;
  }
  @media screen and (max-width: 479px) {
    .onfido-sdk-ui-Modal-inner {
      height: 37.5em !important;
      position: relative !important;
    }
  }
`;

const Onfido: React.FC = () => {
  const { generateToken, createCheck, getCheck } = useOnfido();
  const { applicantId } = useParams();
  const [
    showValidateIdentityResult,
    setShowValidateIdentityResult,
  ] = React.useState(false);
  const [
    showExpiredLink,
    setShowExpiredLink,
  ] = React.useState(false);

  React.useEffect(() => {
    const initializeOnfido = async () => {
      try {
        const response = await getCheck(applicantId)
        console.log('response', response)
        if (response && ((response[0] && response[0]?.status) || (response.error))) {
          setShowExpiredLink(true)
          return
        }
      } catch (e) {
        setShowExpiredLink(true)
        return
      }

      const tokenResponse = await generateToken({
        applicantId,
        referrer: `${window.origin}/*`,
      });

      await onfido.init({
        token: tokenResponse.token,
        containerId: 'onfido-mount',
        language: {
          locale: 'es_ES',
          phrases: {
            'doc_submit.title_id_front': 'Frente de la cédula de identidad',
            'doc_submit.title_id_back': 'Reverso de la cédula de identidad',
            'cross_device.intro.title': 'Validación',
            'cross_device.intro.sub_title':
              'Para tu facilidad continuemos desde tu teléfono celular',
            'cross_device.intro.description_li_1':
              'Escanea el código QR o coloca tu célular para enviarte un link',
            'cross_device.intro.description_li_2':
              'Sigue los pasos que te muestran en la pantalla',
            'cross_device.intro.description_li_3':
              'Vuelve aquí para terminar el registro',
            'cross_device.intro.action': 'ENTENDIDO, CONTINUAR',
            'cross_device.link.sms_option': 'PREFIERO RECIBIR SMS',
            'cross_device.link.title': 'Validación',
            'cross_device.link.qr_code_sub_title':
              'Escanea el código con tu celular para continuar',
            'cross_device.link.qr_code.help_label':
              '¿Cómo escaneo un código QR?',
            'cross_device.link.qr_code.help_step_1':
              'Apunta la cámara de tu celular al QR, se te mostrará el link en la parte superior',
            'cross_device.link.qr_code.help_step_2':
              'Si no te funciona, debes descargar un app para leer QR, o bien puedes ir al boton de ¨Recibir SMS¨ y te enviaremos el link',
            'cross_device.link.button_copy.action': 'Enviar link',
            'cross_device.link.sms_label': 'Número celular',
            'cross_device.phone_number_placeholder':
              'Ingresa tu número celular',
            'cross_device.link.sms_sub_title': 'Enviar link a mi celular',
            'cross_device.mobile_connected.title.message':
              'Conectado con tu celular',
            'cross_device.mobile_connected.title.submessage':
              'Continua el proceso desde tu celular y luego vuelve acá para finalizar el registro',
            'cross_device.tips': 'Toma en cuenta',
            'cross_device.mobile_connected.tips.item_1':
              'Manten esta ventana abierta mientras usas tu celular',
            'cross_device.mobile_connected.tips.item_2':
              'Este link expirará en una hora',
            'cross_device.client_success.title':
              '¡Listo, continua en tu computadora!',
            'cross_device.client_success.sub_title': '  ',
            'cross_device.client_success.body':
              'Puede tardar unos segundo en actualizar el computador',
            'cross_device.mobile_connected.tips.item_3':
              'No actualices esta página hasta finalizar',
            'capture.national_identity_card.front.title':
              'Parte frontal de tu cédula nacional',
            'capture.national_identity_card.back.title':
              'Parte trasera de tu cédula nacional',
            'confirm.national_identity_card.message':
              'Confirma que la foto cumple lo siguiente: Mi documento es legible, La imagen está nítida, No hay sombras ni reflejos',
            'confirm.document.title': 'Comprueba la legibilidad',
            'capture.face.intro.title': '¡Un último paso!',
            'capture.face.intro.subtitle':
              'para finalizar con la validación de identidad necesitamos verificar que eres tu tomando un corto video',
            'capture.face.intro.selfie_instruction': 'Mantente serio',
            'capture.face.intro.glasses_instruction': 'Quitate tus lentes',
            'complete.message': '¡Listo, continua en tu computadora!',
            'complete.submessage':
              'Puede tardar unos segundos en actualizar el computador',
            'cross_device.submit.title': 'Validación',
            'cross_device.submit.sub_title':
              '¡Genial, eso es todo! Estamos listos para verificar su identidad.',
          },
          mobilePhrases: {
            'capture.national_identity_card.front.instructions':
              'Toma la foto de la parte frontal de tu documento de identidad',
            'capture.national_identity_card.back.instructions':
              'Toma la foto de la parte trasera de tu documento de identidad',
            'photo_upload.body_id_back': 'Tome una foto del reverso de su cédula de identidad',
            'photo_upload.body_id_front': 'Tome una foto del frente de su cédula de identidad',
          },
        },
        onComplete: async (data: { face: { variant: string } }) => {
          const reportNames = data.face.variant === 'video' ?
              ['document', 'facial_similarity_photo','facial_similarity_video'] :
              ['document', 'facial_similarity_photo']

          const result = await createCheck({
            applicantId,
            reportNames,
          });
          if (result) setShowValidateIdentityResult(true);
          setTimeout(() => {
            console.log('Enviando mensaje a smart')
            window.parent.postMessage(false, '*');
          }, 2000);
        },
        steps: [
          {
            type: 'document',
            options: {
              forceCrossDevice: true,
              documentTypes: {
                national_identity_card: true,
              },
            },
          },
          {
            type: 'face',
            options: {
              requestedVariant: 'standard',
            },
          },
        ],
      });
    };
    initializeOnfido();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  if (showValidateIdentityResult) return <ValidateIdentityResult />;

  if (showExpiredLink) return <ExpiredLink />;

  return (
    <>
        <Text variant="small" style={{
          paddingBottom: 30, fontSize: 22, color: '#FAA21B', fontWeight: 'bold', textAlign: 'center',
        }}>
          La información brindada se utilizará para la solicitud de apertura de su cuenta.
        </Text>

      <StyledContainer
        style={{
          width: '100%',
          position: 'relative',
          paddingTop: 0,
          maxWidth: 900,
          alignSelf: 'center',
        }}
        id="onfido-mount"
      />
    </>
  );
};

export default Onfido;
