import React, { useState } from 'react'
import {
  Text,
  Box,
  TextInput,
  Button, useMMConfirmAlert,
} from '@akrostechnologies/pei-business-ui'
import { useAutoCompleteInfo, useForm } from '../lib/applicant';
import { useToast } from '../lib/toast';

const Form: React.FC = () => {
  const { getNames } = useAutoCompleteInfo()
  const {
    setLoadingModalVisibility,
    setLoadingModalImage,
    setLoadingModalMessage,
  } = useToast()
  const alert = useMMConfirmAlert()

  const alertUrl = React.useCallback(async (applicantId: string, error?: boolean) => {
    if (error || !applicantId) {
      alert.open({
        title: 'Error',
        message: 'Se ha producido un error. Intente de nuevo.',
        confirmText: 'Aceptar',
      })
    } else {
      const onfidoUrl = `${window.origin}/verification/${applicantId}`
      alert.open({
        title: 'Verificación generada',
        message: 'Click en validar identidad para continuar con el proceso',
        confirmText: 'Validar Identidad',
        onConfirm: () => {
          // Open in same tab
          window.location.replace(onfidoUrl);
          alert.close()
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert])

  const { formal } = useForm(alertUrl)
  const [hasError, setHasError] = useState(true)

  const idOnBlur = React.useCallback(async (sendApplication = false) => {
    // @ts-ignore
    formal.getErrors('id')
      .onBlur()
      .then(async (result) => {
        if (result.error === undefined) {
          setLoadingModalImage('spinner')
          setLoadingModalMessage('Verificando ID')
          setLoadingModalVisibility(true)
          try {
            // @ts-ignore
            const data = await getNames(formal.getFieldProps('id').value)
            // @ts-ignore
            formal.setFieldProps('names', data?.names)
            // @ts-ignore
            formal.setFieldProps('surnames', data?.surnames)
            setLoadingModalVisibility(false)
            if (sendApplication) {
              formal.submit({
                ...formal.values,
                ...data,
              })
            }
          } catch (e) {
            setLoadingModalVisibility(false)
            // @ts-ignore
            formal.setFieldProps('names', '')
            // @ts-ignore
            formal.setFieldProps('surnames', '')
            setHasError(true)
            alert.open({
              title: 'Error',
              message: 'El número de identificación ingresado no existe. Intente de nuevo.',
              confirmText: 'Aceptar',
            })
          }
        } else {
          // @ts-ignore
          formal.setFieldProps('names', '')
          // @ts-ignore
          formal.setFieldProps('surnames', '')
          setHasError(true)
        }
      })
  }, [
    alert,
    formal,
    getNames,
    setLoadingModalImage,
    setLoadingModalMessage,
    setLoadingModalVisibility,
  ])

  return (
        <Box>
            <Text variant="h2" style={{ paddingBottom: 20 }}>
                Verificación de identidad
            </Text>

            <Text variant="small" style={{ paddingVertical: 15 }}>
                Ingresá tu número de cédula (sin guiones)
            </Text>
            <Box style={{ marginVertical: 20 }}>
                <TextInput
                    // @ts-ignore
                    {...formal.getFieldProps('id')}
                    // @ts-ignore
                    {...formal.getErrors('id')}
                    label="Número de identificación"
                    onBlur={idOnBlur}
                    style={{
                      borderBottomColor: '#061f60',
                    }}
                />
            </Box>
            <Box style={{ marginVertical: 20 }}>
                <TextInput
                    // @ts-ignore
                    {...formal.getFieldProps('names')}
                    // @ts-ignore
                    {...(!hasError && formal.getErrors('names'))}
                    label="Nombre"
                    editable={false}
                    style={{
                      borderBottomColor: '#061f60',
                    }}
                />
            </Box>
            <Box style={{ marginVertical: 20 }}>
                <TextInput
                    // @ts-ignore
                    {...formal.getFieldProps('surnames')}
                    // @ts-ignore
                    {...(!hasError && formal.getErrors('surnames'))}
                    label="Apellidos"
                    editable={false}
                    style={{
                      borderBottomColor: '#061f60',
                    }}
                />
            </Box>
            <Button
                variant={'blue'}
                {...formal.getSubmitButtonProps()}
                onPressOut={() => idOnBlur(true)}
                text="Continuar"
                boxProps={{ mt: 32 }}
            />
        </Box>
  )
}
export default Form
