import React from 'react'
import {
  Box,
  Paper,
} from '@akrostechnologies/pei-business-ui'
import Images from '../images/images';

const bgVariants = {
  principal: '#00C3FF',
}
const Footer: React.FC = () => {
  const img = Images.logo

  return (
        <Box
            display="flex"
            alignItems="center"
            marginTop={30}
            zIndex={0}
            backgroundColor="negative"
        >
            <Paper
                width="100%"
                borderRadius="small"
                display="flex"
                flex={1}
                bg="primary"
            >
                <Box
                    bg={bgVariants.principal}
                    py={16}
                    px={32}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <img src={img} alt="img" style={{
                      width: '600px',
                      height: '65px',
                      objectFit: 'contain',
                    }}/>
                </Box>
            </Paper>
        </Box>
  )
}

export default Footer
