import React from 'react'
import ToastContext from '../context/toast'

export const useToast = () => {
  const {
    message,
    setMessage,
    isVisible,
    setIsVisible,
    loadingMessage,
    setLoadingMessage,
    loadingVisibility,
    setLoadingVisibility,
    loadingImage,
    setLoadingImage,
  } = ToastContext.useContainer()

  const setToastMessage = React.useCallback(
    (value) => {
      setMessage(value)
    },
    [setMessage],
  )

  const setToastVisibility = React.useCallback(
    (value) => {
      setIsVisible(value)
    },
    [setIsVisible],
  )

  const setLoadingModalMessage = React.useCallback(
    (value) => {
      setLoadingMessage(value)
    },
    [setLoadingMessage],
  )

  const setLoadingModalVisibility = React.useCallback(
    (value) => {
      setLoadingVisibility(value)
    },
    [setLoadingVisibility],
  )

  const setLoadingModalImage = React.useCallback(
    (value) => {
      setLoadingImage(value)
    },
    [setLoadingImage],
  )

  return {
    message,
    isVisible,
    loadingMessage,
    loadingVisibility,
    loadingImage,
    setToastMessage,
    setToastVisibility,
    setLoadingModalMessage,
    setLoadingModalVisibility,
    setLoadingModalImage,
  }
}
