import React from "react";
import Onfido from "./pages/onfido";
import AppLayout from "./components/layout";
import Footer from "./components/footer";

function OnfidoApp() {
  return (
    <AppLayout bg="body" footer={<Footer />}>
      <Onfido />
    </AppLayout>
  );
}

export default OnfidoApp;
