import React from "react";

import { useMedia, Box, Paper } from "@akrostechnologies/pei-business-ui";
import Images from "../images/images";

const bgVariants = {
  principal: "#001E64",
  body: "linear-gradient(90deg,#847dff -9.93%,#00b9ff 49.51%,#00d4c7 107.78%);",
};

const AppLayout: React.FC<{
  bg: keyof typeof bgVariants;
  footer?: React.ReactNode;
  bodyPaddingY?: boolean;
}> = ({ children, bg, bodyPaddingY = true }) => {
  const { isMobile } = useMedia();
  const img = Images.logo;

  return (
    <Box
      style={{
        background:
          "linear-gradient(transparent 50%, white 0%), linear-gradient(90deg, rgb(129, 132, 252) 0%, rgb(31, 184, 252) 100%)",
      }}
      display="flex"
      p={0}
      alignItems="center"
      zIndex={0}
    >
      <Box
        minHeight="100vh"
        display="flex"
        p={isMobile ? 0 : 32}
        alignItems="center"
        zIndex={0}
      >
        <Paper
          width="100%"
          maxWidth={800}
          borderRadius="small"
          display="flex"
          flex={isMobile ? 1 : 0}
          marginTop={isMobile ? 0 : "5%"}
        >
          <Box
            px={isMobile ? 32 : "15%"}
            display="flex"
            py={15}
            justifyContent="center"
            alignItems={"center"}
            boxProps={{ width: "88px", height: "54px" }}
          >
            <img
              src={img}
              alt="img"
              style={{
                paddingTop: 20,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
          <Box
            bg={bgVariants[bg]}
            px={isMobile ? 32 : "15%"}
            py={bodyPaddingY === true ? "30px" : 0}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            flex={1}
          >
            {children}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AppLayout;
