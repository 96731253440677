import React from 'react';

const { REACT_APP_MM_ONFIDO_API } = process.env

export const useOnfido = () => {
  const createApplicant = React.useCallback(async (request: {
      id: string,
      names: string,
      surnames: string
  }) => {
    const response = await fetch(
      `${REACT_APP_MM_ONFIDO_API}/onfido/applicant`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      },
    )
    return response.json()
  }, [])

  const generateToken = React.useCallback(async (request: {
      applicantId: string,
      referrer: string
  }) => {
    const response = await fetch(
      `${REACT_APP_MM_ONFIDO_API}/onfido/token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      },
    )
    return response.json()
  }, [])

  const createCheck = React.useCallback(async (request: {
      applicantId: string,
      reportNames: Array<string>
  }) => {
    const response = await fetch(
      `${REACT_APP_MM_ONFIDO_API}/onfido/check`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      },
    )
    return response.json()
  }, [])

  const getCheck = React.useCallback(async (applicantId: string) => {
    const response = await fetch(
      `${REACT_APP_MM_ONFIDO_API}/onfido/check/${applicantId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return response.json()
  }, [])

  const uploadIdPhoto = React.useCallback(async (request: {
        applicantId: string,
        id: string
    }) => {
    const response = await fetch(
      `${REACT_APP_MM_ONFIDO_API}/onfido/photo`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      },
    )
    return response.json()
  }, [])

  return {
    createApplicant, generateToken, createCheck, uploadIdPhoto, getCheck,
  }
}
