import React from 'react'
import {
  Text, Banner, Box,
} from '@akrostechnologies/pei-business-ui'

const ValidateIdentityResult: React.FC = () => (
  <Box>
    <div style={{ width: '70%', height: '100%' }}>
      <Banner imageId="validateIdentityResult" width="100%" />
    </div>
    <div style={{ height: '30px' }} />
    <Text variant="h2">
        ¡Genial! Estamos listos para continuar con tu solicitud de apertura de cuenta.
    </Text>
  </Box>
)

export default ValidateIdentityResult
