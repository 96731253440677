import React from 'react';
import Form from './pages/form';
import AppLayout from './components/layout';
import Footer from './components/footer';

function App() {
  return (
    <AppLayout bg="body" footer={<Footer />}>
      <Form/>
    </AppLayout>
  );
}

export default App;
