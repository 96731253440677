import * as yup from 'yup'
import React from 'react';
import { useFormal } from './formal';
import applicantContext from '../context/applicant';
import { useOnfido } from './onfido';
import { useToast } from './toast';

const { REACT_APP_MM_ONFIDO_API } = process.env
export const useForm = (nextStep: Function) => {
  const { setPersonalInformation } = applicantContext.useContainer()
  const { createApplicant, uploadIdPhoto } = useOnfido()
  const {
    setLoadingModalVisibility,
    setLoadingModalImage,
    setLoadingModalMessage,
  } = useToast()

  const formal = useFormal({
    initialValues: {
      id: '',
      names: '',
      surnames: '',
    },
    validationSchema: yup.object().shape({
      id: yup
        .string()
        .required('Id requerido')
        .test('format invalid', 'Formato inválido', (value) => /^[0-9]{9}([0-9]{3})?$/.test(value || '')),
      names: yup
        .string()
        .required('Nombre requerido')
        .min(3, 'Nombre inválido'),
      surnames: yup
        .string()
        .required('Apellido requerido')
        .min(3, 'Apellido inválido'),
    }),
    canSubmit: (values) => Boolean(values && values.id && values.names && values.surnames),
    onSubmit: async (values) => {
      if (values) {
        setLoadingModalImage('spinner')
        setLoadingModalMessage('Generando URL')
        setLoadingModalVisibility(true)
        setPersonalInformation(values)
        try {
          const response = await createApplicant({
            id: values.id,
            names: values.names,
            surnames: values.surnames,
          })
          if (response && response.id) {
            await uploadIdPhoto({
              applicantId: response.id,
              id: values.id,
            })
          }
          setLoadingModalVisibility(false)
          nextStep(response?.id, false)
        } catch (e) {
          setLoadingModalVisibility(false)
          // eslint-disable-next-line no-console
          console.error(e)
          nextStep('', true)
        }
      }
    },
  })

  return { formal }
}

export const useAutoCompleteInfo = () => {
  const getNames = React.useCallback(async (id: string) => {
    const response = await fetch(
      `${REACT_APP_MM_ONFIDO_API}/people/${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return response.json()
  }, [])

  return { getNames }
}
