import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AppProvider from "./providers/app";
import OnfidoApp from "./Onfido";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <React.StrictMode>
            <AppProvider>
              <App />
            </AppProvider>
          </React.StrictMode>
        }
      />
      <Route
        path="/verification/:applicantId"
        element={
          <React.StrictMode>
            <AppProvider>
              <OnfidoApp />
            </AppProvider>
          </React.StrictMode>
        }
      />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
