import React from 'react'
import { createContainer } from 'unstated-next'

const applicantContext = createContainer(() => {
  const [personalInformation, setPersonalInformation] = React.useState<{
    id: string
    names: string
    surnames: string
  }>()

  return {
    personalInformation,
    setPersonalInformation,
  }
})

export default applicantContext
